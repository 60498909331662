<template id="tmplCustomersView">
  <div class="container vld-parent" id="divCustomersView">
    <loading
      id="ldingCustomers"
      :active.sync="isLoading"
      :is-full-page="true"
      color="#114C8E"
    ></loading>
    <b-row id="rowCustomersView">
      <h1 class="text-primary" id="h1CustomersView">Customers</h1>
    </b-row>
    <b-row id="rowAddNewCustomerParent">
      <b-card style="width: 100%" id="crdAddNewCustomer">
        <b-row class="mb-2" id="rowAddNewCustomer">
          <b-col id="colAddNewCustomer">
            <b-button
              id="btnAddNewCustomer"
              v-if="canCreateCustomers"
              variant="primary"
              @click="showCustomerSlideIn('Add Customer', null)"
            >
              <i class="fa fa-plus mr-1" id="iAddNewCustomer"></i> Add New Customer
            </b-button>
          </b-col>
        </b-row>

        <b-row align-h="between" class="my-3" id="rowSearchCustomers">
          <b-col md="6" class="my-1" id="colSearchCustomers">
            <b-form inline id="frmSearchCustomers">
              <label class="mr-sm-2 col-form-label-sm" for="filterInput" id="lblSearchCustomers"
                >Search:</label
              >
              <b-input-group size="sm" id="grpSearchCustomers">
                <b-form-input
                  v-model="filter"
                  type="search"
                  id="filterInput"
                  placeholder="Type to Search"
                ></b-form-input>
                <b-input-group-append id="inpClearCustomers">
                  <b-button :disabled="!filter" @click="filter = ''" id="btnClearCustomers"
                    >Clear</b-button
                  >
                </b-input-group-append>
              </b-input-group>
            </b-form>
          </b-col>

          <b-col md="6" class="my-1" id="colPerPage">
            <b-form inline class="float-md-right" id="frmPerPage">
              <label class="mr-sm-2 col-form-label-sm" for="perPageSelect" id="lblPerPage"
                >Per page:</label
              >
              <b-form-select
                v-model="perPage"
                id="perPageSelect"
                size="sm"
                :options="pageOptions"
              ></b-form-select>
            </b-form>
          </b-col>
        </b-row>

        <b-row id="rowCustomersTable">
          <b-col id="colCustomersTable">
            <b-table
              id="tblCustomers"
              :items="customers"
              :fields="fields"
              responsive="sm"
              striped
              outlined
              :current-page="currentPage"
              :per-page="perPage"
              :filter="filter"
              @filtered="onFiltered"
              :sort-direction="sortDirection"
            >
              <template v-slot:cell(actions)="data" id="tblEditCustomer">
                <b-button
                  v-if="canViewUsers"
                  size="sm"
                  variant="success"
                  class="mb-1 mr-2"
                  id="btnEditCustomer"
                  @click="editUserCustomers(data.item)"
                >
                  <i class="fa fa-users mr-1" id="iEditUsers"></i>
                  Users
                </b-button>

                <b-button
                  v-if="canUpdateCustomers"
                  size="sm"
                  variant="primary"
                  class="mb-1 mr-2"
                  id="btnEditCustomer"
                  @click="showCustomerSlideIn('Edit Customer', data.item)"
                >
                  <i class="fa fa-edit mr-1" id="iEditCustomer"></i>
                  Edit
                </b-button>

                <b-button
                  id="btnDeleteCustomers"
                  v-if="canDeleteCustomers"
                  size="sm"
                  variant="danger"
                  class="mb-1 btn-sm"
                  style="cursor:pointer"
                  @click="confirmDeleteCustomer(data.item)"
                >
                  <i class="fa fa-trash mr-1" id="iDeleteCustomer"></i>
                  Delete
                </b-button>
              </template>
            </b-table>

            <b-pagination
              id="pgnPerPage"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              last-number
              class="my-2"
            ></b-pagination>
            Total Records : {{ totalRows }}
          </b-col>
        </b-row>
      </b-card>
    </b-row>
  </div>
</template>

<script>

import { handleRequestError } from "@/shared/utils/response-error-handler";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import _ from "lodash";

import { RepositoryFactory } from "@/services/RepositoryFactory";
const CustomersRepository = RepositoryFactory.get("customersRepository");

import {
  canViewUsers,
  canCreateCustomers,
} from "@/shared/utils/permissions";

export default {
  name:"Customers.vue",
  components: {
    Loading,
    Event
  },
  data() {
    return {
      customers: [],
      isLoading: false,
      showModal: false,
      modalTitle: null,
      perPage: 10,
      totalRows: 1,
      currentPage: 1,
      pageOptions: [5, 10, 25, 50, 100, 500],
      filter: null,
      sortDirection: "asc",
      fields: [
        { key: "name", label: "Name", sortable: true },
        { key: "actions", label: "Actions" }
      ]
    };
  },
  methods: {
    async editUserCustomers(customer) {
      this.$router.push({
        name: "external-admin-users",
        query: { customerId: customer.id }
      });
    },
    showCustomerSlideIn(title, customer) {
      if (customer == null) {
        this.$router.push({
          name: "admin-create-customer"
        });

        return;
      }

      this.$router.push({
        name: "admin-edit-customer",
        params: { id: customer.id }
      });
    },
    confirmDeleteCustomer(customer) {
      this.$snotify.error(
        "Are you sure you want to delete Customer: " + customer.name,
        "Delete",
        {
          backdrop: 0.3,
          timeout: 0,
          closeOnClick: false,
          position: "centerCenter",
          buttons: [
            {
              text: "Ok",
              action: async toast => {
                await this.deleteCustomer(customer);
                this.$snotify.remove(toast.id);
              },
              bold: true
            },
            { text: "Cancel", action: null, bold: false }
          ]
        }
      );
    },
    async deleteCustomer(customer) {
      try {
        customer.activeTripFocus = false;

        const requestData = {
          id: customer.id,
          customerData: customer
        };

        await CustomersRepository.update(requestData);

        const index = _.findIndex(this.customers, function(item) {
          return item.id === customer.id;
        });

        this.customers.splice(index, 1);

        this.totalRows = this.customers.length;

        this.$snotify.success(`Customer deleted successfully.`, "Success", {
          timeout: 1500,
          showProgressBar: true
        });
      } catch (error) {
        const errorMessage = handleRequestError(error);
        this.$snotify.error(errorMessage, "Error");
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  },
  async created() {
    this.isLoading = true;

    //handles modal closing from child component
    this.$root.$on("toggleModalState", val => {
      this.showModal = val;
    });

    try {
      let customerResult = await CustomersRepository.get();

      this.customers = customerResult.data;
    } catch (error) {
      const errorMessage = handleRequestError(error);
      this.$snotify.error(errorMessage, "Error");
    }
    this.isLoading = false;
    this.totalRows = this.customers.length;
  },
  mounted() {},
  computed: {
    canViewUsers() {
      return canViewUsers(this.$store);
    },
    canCreateCustomers() {
      return canCreateCustomers(this.$store);
    },
    canUpdateCustomers() {
      return canCreateCustomers(this.$store);
    },
    canDeleteCustomers() {
      return canCreateCustomers(this.$store);
    }
  }
};
</script>

<style scoped>
</style>
